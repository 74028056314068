import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Spinner,
  Alert,
  Col,
  Table,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllContractors,
  approveContractor,
  deleteCont,
} from "../../../slices/thunk";
import { Link } from "react-router-dom";

const AdminContratorsData = () => {
  document.title = "Contractor Data";
  const [loading, setLoading] = useState(true);
  const [modal_center, setmodal_center] = useState(false);
  const [contId, setSelectContId] = useState("");
  const [notification, setNotification] = useState({
    show: false,
    variant: "",
    message: "",
  }); // Notification state

  const allcontractor = useSelector((state: any) => state.Admin.contractors);

  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getAllContractors("real_estate_agent"));
  }, []);
  const handleSwitchToggle = async (id: string, event: any) => {
    try {
      const isChecked = event;
      const newValue = isChecked === 1 ? 0 : 1;
      dispatch(approveContractor(isChecked, id));
      dispatch(getAllContractors("real_estate_agent"));
    } catch (error) {
      console.error("Error updating switch:", error);
      // Handle error, e.g., show error message to the user
    }
  };
  const handleselect = (ele: any) => {
    dispatch(getAllContractors(ele));
  };

  function tog_center(id: any) {
    setmodal_center(!modal_center);
    setSelectContId(id);
  }

  function handleDelete(id: any) {
    dispatch(deleteCont(id)).then((resp: any) => {
      if (resp) {
        setNotification({
          show: true,
          variant: "success",
          message: resp.message,
        });
        dispatch(getAllContractors("real_estate_agent"));
        setmodal_center(!modal_center);
      }
    });
  }
  return (
    <React.Fragment>
      <div className="page-content">
        {notification.show && (
          <Alert
            variant={notification.variant}
            onClose={() =>
              setNotification({ show: false, variant: "", message: "" })
            }
            dismissible
          >
            {notification.message}
          </Alert>
        )}
        <Container fluid>
          <BreadCrumb title="Realestate Agents" pageTitle="Pages" />
          <Row>
            <Col xl={12}>
              <div className="table-responsive mt-4 mt-xl-0">
                <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">S.no</th>
                      <th scope="col">ID</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Created At Date</th>
                      <th scope="col">Created At Time</th>
                      <th scope="col">Phone</th>
                      <th scope="col">User Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                      <th scope="col">View</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allcontractor.length &&
                      allcontractor.map((item: any, index: any) => (
                        <tr key={item.id}>
                          <td className="fw-medium">{index + 1}</td>
                          <td className="fw-medium">{item.id}</td>
                          <td>{item.user_first_name}</td>
                          <td>{item.user_last_name}</td>
                          <td>{item.user_email}</td>
                          <td>
                            {new Date(item.created_at).toLocaleDateString(
                              "en-US",
                              {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              }
                            )}
                          </td>
                          <td>
                            {new Date(item.created_at).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true,
                              }
                            )}
                          </td>
                          <td>{item.user_phone}</td>
                          <td>{item.user_type}</td>
                          <td>
                            <i className="ri-checkbox-circle-line align-middle text-success"></i>{" "}
                            {item.is_verified === 0 ? "No" : "Yes"}
                          </td>
                          <td>
                            <div className="form-check form-switch">
                              <Form.Check
                                className="form-switch"
                                type="switch"
                                id={`SwitchCheck-${item.id}`}
                                onChange={(e) =>
                                  handleSwitchToggle(item.id, e.target.checked)
                                }
                                checked={item.is_verified === 1 ? true : false}
                              />
                              <Form.Label
                                className="ms-2"
                                htmlFor="SwitchCheck1"
                              >
                                No/Yes
                              </Form.Label>
                            </div>
                          </td>
                          <td>
                            <Link
                              to={`/pages-single-contractors?id=${item.id}`}
                              className="text-reset"
                            >
                              <Button>View</Button>
                            </Link>
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() => tog_center(item.id)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        show={modal_center}
        onHide={() => {
          tog_center("");
        }}
        centered
      >
        <Modal.Body className="text-center p-5">
          <i className="bi bi-exclamation-triangle text-warning display-5"></i>
          <div className="mt-4">
            <h4 className="mb-3">Please Confirm Deletion</h4>
            <p className="text-muted mb-4">
              {" "}
              You are about to delete this record. This action cannot be undone.
              Are you sure you want to proceed?
            </p>
            <div className="hstack gap-2 justify-content-center">
              <Button variant="light" onClick={() => setmodal_center(false)}>
                Cancel
              </Button>
              <Button
                className="btn btn-danger"
                onClick={() => handleDelete(contId)}
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AdminContratorsData;
