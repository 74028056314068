import React, { useEffect, useState } from "react";
import {
  Accordion,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Nav,
  Row,
  Tab,
  Spinner,
} from "react-bootstrap";
import Breadcrumb from "Common/BreadCrumb";
import CustomDropdownToggle from "Common/CustomDropdownToggle";

//Images
import productimg1 from "../../../../assets/images/products/32/img-1.png";
import productimg6 from "../../../../assets/images/products/32/img-6.png";
import productimg7 from "../../../../assets/images/products/32/img-7.png";

import DiscountCard from "./DiscountCard";
import CourseIncludes from "./CourseIncludes";
import InstructorDetails from "./InstructorDetails";
import Membership from "./Membership";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getLeadById } from "../../../slices/thunk";
import NoVideo from "../../../assets/images/no-video.jpg";

//SimpleBar
import SimpleBar from "simplebar-react";
import { useTranslation } from "react-i18next";

const Overview = () => {
  const dispatch: any = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const [loading, setLoading] = useState(false);
  const [leadData, setLeadData] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  //const leadData = useSelector((state: any) => state.Leads.singleLead);
  const { t } = useTranslation(); //

  useEffect(() => {
    setLoading(true);
    dispatch(getLeadById(id)).then((resp: any) => {
      setLeadData(resp);
    });
    setLoading(false);
  }, []);

  const adjustImageSize = (
    imgElement: HTMLImageElement,
    maxWidth: number,
    maxHeight: number
  ) => {
    const img = new Image();
    img.src = imgElement.src;

    img.onload = function () {
      let { width, height } = img;
      const aspectRatio = width / height;

      if (width > maxWidth) {
        width = maxWidth;
        height = width / aspectRatio;
      }

      if (height > maxHeight) {
        height = maxHeight;
        width = height * aspectRatio;
      }

      imgElement.style.width = `${width}px`;
      imgElement.style.height = `${height}px`;
      imgElement.style.objectFit = "contain";
    };
  };

  const renderMedia = (url: any) => {
    // Get the file extension
    const fileExtension = url.split(".").pop().toLowerCase();

    // Define supported video extensions
    const videoExtensions = ["mp4", "mov", "avi", "mkv"];

    // Check if the file is a video
    if (videoExtensions.includes(fileExtension)) {
      return (
        <video controls src={url}>
          Your browser does not support the video tag.
        </video>
      );
    }

    // Check if the file is an image

    const validExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];

    if (!validExtensions.includes(fileExtension)) return null;

    return (
      <>
        {/* Small Image */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(true)}
        >
          <img
            src={url}
            onLoad={(e) => adjustImageSize(e.currentTarget, 300, 200)}
            style={{
              transition: "0.3s ease-in-out",
              borderRadius: "5px",
            }}
            alt="media"
          />
        </div>

        {/* Popup (Modal) */}
        {isOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
            onClick={() => setIsOpen(false)}
          >
            <img
              src={url}
              style={{
                maxWidth: "90%",
                maxHeight: "90%",
                borderRadius: "10px",
                transition: "0.3s ease-in-out",
                cursor: "pointer",
              }}
              alt="media"
            />
          </div>
        )}
      </>
    );
    return <p>Unsupported media type</p>;
  };

  document.title = t("Project Overview");
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Overview" pageTitle="Leads" />
          <div>
            {loading ? ( // Show spinner while loading
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <Row>
                <Col xl={9} lg={8}>
                  <Tab.Container defaultActiveKey="description">
                    <Card>
                      <Card.Body>
                        <div className="position-relative rounded overflow-hidden mb-4">
                          <h4>{leadData && leadData.lead_cat_id}</h4>
                          <h6>{leadData && leadData.lead_sub_cat}</h6>
                          <div className="ratio ratio-21x9 new-overview-lead">
                            {leadData && leadData.lead_video_url ? (
                              renderMedia(leadData.lead_video_url)
                            ) : (
                              <div className="d-flex align-items-center justify-content-center">
                                <img
                                  src={NoVideo}
                                  style={{ width: "45%", marginTop: "20px" }}
                                />
                              </div>
                            )}
                          </div>
                          <div className="position-absolute d-flex gap-2 top-0 end-0 m-3 d-none">
                            <Dropdown>
                              <Dropdown.Toggle
                                isButton={true}
                                as={CustomDropdownToggle}
                                className="btn bg-white btn-icon"
                              >
                                <i className="bi bi-share"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu-end arrow-none">
                                <Dropdown.Item href="#">
                                  <i className="bi bi-facebook me-1 align-baseline"></i>{" "}
                                  Facebook
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                  <i className="bi bi-instagram me-1 align-baseline"></i>{" "}
                                  Instagram
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                  <i className="bi bi-whatsapp me-1 align-baseline"></i>{" "}
                                  Whatsapp
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <Button
                          variant="subtle-warning"
                          className="custom-toggle btn-icon btn-sm active float-end"
                          data-bs-toggle="button"
                          aria-pressed="true"
                        >
                          <span className="icon-on">
                            <i className="bi bi-star"></i>
                          </span>
                          <span className="icon-off">
                            <i className="bi bi-star-fill"></i>
                          </span>
                        </Button>

                        <ul className="list-unstyled hstack gap-2 flex-wrap mb-0">
                          <li>
                            <Link to="#!" className="text-reset">
                              <i className="bi bi-box-seam align-baseline me-1"></i>{" "}
                              {leadData && leadData.lead_sub_cat}
                            </Link>
                          </li>
                          <li className="text-warning">
                            <i className="bi bi-star-fill align-baseline"></i>
                            <i className="bi bi-star-fill align-baseline"></i>
                            <i className="bi bi-star-fill align-baseline"></i>
                            <i className="bi bi-star-fill align-baseline"></i>
                            <i className="bi bi-star-half align-baseline"></i>
                            <span className="text-body ms-1">4.5</span>
                          </li>
                          <li>
                            <span className="badge bg-success-subtle text-success">
                              {leadData && leadData.lead_job_status}
                            </span>
                          </li>
                        </ul>
                      </Card.Body>
                      <Card.Body>
                        <Nav
                          as="ul"
                          variant="tabs"
                          className="nav-tabs-custom rounded card-header-tabs border-bottom-0 mx-0"
                        >
                          <Nav.Item as="li">
                            <Nav.Link eventKey="description">
                              Description
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="video">
                              Contact Information
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="reviews">Reviews</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="faqs">FAQs</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Card.Body>
                    </Card>
                    <Card>
                      <Card.Body>
                        <Tab.Content>
                          <Tab.Pane eventKey="description" id="description">
                            <>
                              <h6 className="card-title mb-3">Description</h6>
                              <p className="text-muted mb-2">
                                {leadData && leadData.lead_description}
                              </p>
                            </>
                          </Tab.Pane>
                          <Tab.Pane eventKey="video" id="videoTutorials">
                            <>
                              <h6 className="card-title mb-3">Address</h6>
                              <p className="text-muted mb-2">
                                {leadData && leadData.lead_address},{" "}
                                {leadData && leadData.lead_city},{" "}
                                {leadData && leadData.lead_state},{" "}
                                {leadData && leadData.lead_zip_code}
                              </p>
                            </>
                          </Tab.Pane>
                          <Tab.Pane eventKey="reviews" id="reviews">
                            {/* <div className="d-flex flex-wrap align-items-center gap-3 mb-2">
                              <h6 className="card-title flex-grow-1 mb-0">
                                Ratings & Reviews
                              </h6>
                              <div className="flex-shrink-0">
                                <Button variant="primary">
                                  <i className="ph-plus-circle align-middle me-1"></i>{" "}
                                  Add Review
                                </Button>
                              </div>
                            </div> */}
                            {/* <Row className="gy-3">
                              <Col lg={2}>
                                <div className="text-center mt-3 mt-lg-5">
                                  <h1 className="mb-3">
                                    4.9{" "}
                                    <small className="fs-sm text-muted fw-normal">
                                      / 5.0
                                    </small>
                                  </h1>
                                  <div className="text-warning hstack gap-2 justify-content-center mb-2">
                                    <i className="bi bi-star-fill align-baseline"></i>
                                    <i className="bi bi-star-fill align-baseline"></i>
                                    <i className="bi bi-star-fill align-baseline"></i>
                                    <i className="bi bi-star-fill align-baseline"></i>
                                    <i className="bi bi-star-fill align-baseline"></i>
                                  </div>
                                  <p className="mb-0">
                                    <b>3.5k</b> Reviews
                                  </p>
                                </div>
                              </Col>
                              <Col lg={10}>
                                <div>
                                  <SimpleBar
                                    className="me-lg-n3 pe-lg-4"
                                    style={{ maxHeight: "500px" }}
                                  >
                                    <ul
                                      className="list-unstyled mb-0"
                                      id="review-list"
                                    >
                                      <li
                                        className="review-list py-2"
                                        id="review-1"
                                      >
                                        <div className="border border-dashed rounded p-3">
                                          <div className="hstack flex-wrap gap-3 mb-4">
                                            <Badge
                                              bg="danger-subtle"
                                              className="rounded-pill text-danger mb-0"
                                            >
                                              <i className="mdi mdi-star"></i>{" "}
                                              <span className="rate-num">
                                                4.5
                                              </span>
                                            </Badge>
                                            <div className="vr"></div>
                                            <div className="flex-grow-1">
                                              <p className="mb-0">
                                                <Link to="#!">Jansh Brown</Link>
                                              </p>
                                            </div>
                                            <div className="flex-shrink-0">
                                              <span className="text-muted fs-sm mb-0">
                                                08 Mar, 2023
                                              </span>
                                            </div>
                                            <div className="flex-shrink-0 d-flex gap-1">
                                              <Link
                                                to="#addReview"
                                                className="badge bg-secondary-subtle text-secondary edit-item-list"
                                                data-bs-toggle="modal"
                                              >
                                                <i className="ph-pencil align-baseline me-1"></i>{" "}
                                                Edit
                                              </Link>
                                              <Link
                                                to="#removeItemModal"
                                                className="badge bg-danger-subtle text-danger"
                                                data-bs-toggle="modal"
                                              >
                                                <i className="ph-trash align-baseline"></i>
                                              </Link>
                                            </div>
                                          </div>

                                          <h6 className="review-title fs-md">
                                            Nice
                                          </h6>
                                          <p className="review-desc mb-0">
                                            &quot; This is an incredible
                                            framework worth so much in the right
                                            hands! Nowhere else are you going to
                                            get so much flexibility and great
                                            code for a few dollars. Highly
                                            recommend purchasing today! Like
                                            right now! &quot;
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        className="review-list py-2"
                                        id="review-2"
                                      >
                                        <div className="border border-dashed rounded p-3">
                                          <div className="hstack flex-wrap gap-3 mb-4">
                                            <Badge
                                              bg="danger-subtle"
                                              className="rounded-pill text-danger mb-0"
                                            >
                                              <i className="mdi mdi-star"></i>{" "}
                                              <span className="rate-num">
                                                4.0
                                              </span>
                                            </Badge>
                                            <div className="vr"></div>
                                            <div className="flex-grow-1">
                                              <p className="mb-0">
                                                <Link to="#!">
                                                  Camilla Harber
                                                </Link>
                                              </p>
                                            </div>
                                            <div className="flex-shrink-0">
                                              <p className="text-muted fs-sm mb-0">
                                                03 Mar, 2023
                                              </p>
                                            </div>
                                            <div className="flex-shrink-0 d-flex gap-1">
                                              <Link
                                                to="#addReview"
                                                className="badge bg-secondary-subtle text-secondary edit-item-list"
                                                data-bs-toggle="modal"
                                              >
                                                <i className="ph-pencil align-baseline me-1"></i>{" "}
                                                Edit
                                              </Link>
                                              <Link
                                                to="#removeItemModal"
                                                className="badge bg-danger-subtle text-danger"
                                                data-bs-toggle="modal"
                                              >
                                                <i className="ph-trash align-baseline"></i>
                                              </Link>
                                            </div>
                                          </div>

                                          <h6 className="review-title fs-md">
                                            Product Quality
                                          </h6>
                                          <p className="review-desc mb-0">
                                            " We have used your other templates
                                            as well and seems it's amazing with
                                            the design and code quality. Wish
                                            you best for the future updates.
                                            Keep updated you will be #1 in near
                                            future. "
                                          </p>
                                        </div>
                                      </li>
                                    </ul>
                                  </SimpleBar>
                                </div>
                              </Col>
                            </Row> */}
                          </Tab.Pane>
                          <Tab.Pane eventKey="faqs" id="faqs">
                            {/* <Row className="g-3">
                              <Col lg={6}>
                                <div className="d-flex gap-2">
                                  <div className="flex-shrink-0">
                                    <i className="bi bi-question-circle text-primary"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="card-title">
                                      What are good questions to ask about
                                      course?
                                    </h6>
                                    <p className="text-muted mb-0">
                                      What textbooks are required for the
                                      course? What days/times do class sessions
                                      occur or are they fully online? Are there
                                      any online platforms, programs, and/or
                                      softwares needed for the course? When are
                                      major assignments/ exams due for this
                                      course and what are its point values?
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="d-flex gap-2">
                                  <div className="flex-shrink-0">
                                    <i className="bi bi-question-circle text-primary"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="card-title">
                                      How does online learning work?
                                    </h6>
                                    <p className="text-muted mb-0">
                                      Distance learning is an educational
                                      process where students receive instruction
                                      through online classes, video recordings,
                                      video conferencing, or any other
                                      audio/visual technology medium.
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="d-flex gap-2">
                                  <div className="flex-shrink-0">
                                    <i className="bi bi-question-circle text-primary"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="card-title">
                                      Why is online learning better?
                                    </h6>
                                    <p className="text-muted mb-0">
                                      Because online courses provide students
                                      with full control over their studies, they
                                      can work at their own pace. Pupils, on
                                      average, work faster and absorb more
                                      information in online courses than they
                                      would otherwise.
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="d-flex gap-2">
                                  <div className="flex-shrink-0">
                                    <i className="bi bi-question-circle text-primary"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="card-title">
                                      How successful is online learning?
                                    </h6>
                                    <p className="text-muted mb-0">
                                      Some students do as well in online courses
                                      as in in-person courses, some may actually
                                      do better, but, on average, students do
                                      worse in the online setting, and this is
                                      particularly true for students with weaker
                                      academic backgrounds.
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="d-flex gap-2">
                                  <div className="flex-shrink-0">
                                    <i className="bi bi-question-circle text-primary"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="card-title">
                                      How do you ask questions about learning?
                                    </h6>
                                    <p className="text-muted mb-0">
                                      Avoid asking leading questions, those that
                                      prompt or suggest the answer, and yes/no
                                      questions. If a yes/no question is
                                      warranted, be ready with a follow-up
                                      question to encourage students to
                                      critically evaluate the material and
                                      engage in discussion.
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="d-flex gap-2">
                                  <div className="flex-shrink-0">
                                    <i className="bi bi-question-circle text-primary"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="card-title">
                                      What are course objectives?
                                    </h6>
                                    <p className="text-muted mb-0">
                                      A course objective specifies a behavior,
                                      skill, or action that a student can
                                      demonstrate if they have achieved mastery
                                      of the objective.
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="d-flex gap-2">
                                  <div className="flex-shrink-0">
                                    <i className="bi bi-question-circle text-primary"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="card-title">
                                      What is the advantage of learning?
                                    </h6>
                                    <p className="text-muted mb-0">
                                      By consistently educating yourself and
                                      trying new things, you'll learn you're
                                      capable of change and growth, which keeps
                                      you open to new opportunities in life.
                                      “Learning a new skill can get you out of a
                                      rut.
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row> */}
                          </Tab.Pane>
                        </Tab.Content>
                      </Card.Body>
                    </Card>
                  </Tab.Container>
                </Col>
                <Col xl={3} lg={4}>
                  <InstructorDetails data={leadData} />
                  <DiscountCard data={leadData} />
                  <CourseIncludes />
                  {/* <Membership /> */}
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Overview;
