import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { getDatabase, ref, set } from "firebase/database";
import translationDe from "Common/locales/de.json";
import translationIT from "Common/locales/it.json";
import translationRU from "Common/locales/ru.json";
import translationSP from "Common/locales/sp.json";
import translationENG from "Common/locales/en.json";
import translationZh from "Common/locales/zh.json";
import translationFR from "Common/locales/fr.json";
import translationAR from "Common/locales/ar.json";

const GOOGLE_TRANSLATE_API_KEY = "AIzaSyCBzdvSSdPhOsswLd4bKd_kS1GYCLl_L_s";

const fetchGoogleTranslation = async (text: string, targetLang: string): Promise<string> => {
  try {
    const response = await fetch(
      `https://translation.googleapis.com/language/translate/v2?key=${GOOGLE_TRANSLATE_API_KEY}`,
      {
        method: "POST",
        body: JSON.stringify({
          q: text, // ✅ Keep text as a string or an array of strings
          target: targetLang, // ✅ Must be a string, not an array
          source: "en", // Optional: Define source language
          format: "text",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    
    if (data?.error) {
      console.error("Google Translate API Error:", data.error);
      return text; // Fallback to original text
    }

    return data?.data?.translations?.[0]?.translatedText || text;
  } catch (error) {
    console.error("Translation API Error:", error);
    return text;
  }
};

const saveToLocalStorage = (lng: string, key: string, translatedText: string) => {
  const storedData = localStorage.getItem("missingTranslations");
  const translations = storedData ? JSON.parse(storedData) : {}; // Parse existing data

  if (!translations[lng]) {
    translations[lng] = {};
  }
  translations[lng][key] = translatedText; // Store translation

  localStorage.setItem("missingTranslations", JSON.stringify(translations));
  console.log(`📥 Saved to localStorage: [${lng}] ${key} → ${translatedText}`);
};
// Function to handle missing translations
const handleMissingKey = async (lng: string, key: string) => {
  try {
    const translatedText = await fetchGoogleTranslation(key, lng);

    if (translatedText ) {
      console.log(`✅ Adding translation: [${lng}] ${key} → ${translatedText}`);
      // await saveTranslationToFirebase(lng, key, translatedText);
      // ✅ Add translation dynamically
      saveToLocalStorage(lng, key, translatedText);
      i18n.addResource(lng, "translation", key, translatedText);
      i18n.changeLanguage(i18n.language);
      // ✅ Notify components to update translations
      // i18n.emit("languageChanged", lng); // 🔥 Best way to trigger a UI update
    }else{
      console.log(`----- no Adding translation: [${lng}] ${key} → ${translatedText}`);

    }

    return translatedText;
  } catch (error) {
    console.error("❌ Failed to add missing translation:", error);
    return key; // Fallback to original text
  }
};



const resources = {
  de: { translation: translationDe },
  it: { translation: translationIT },
  ru: { translation: translationRU },
  es: { translation: translationSP },
  en: { translation: translationENG },
  zh: { translation: translationZh },
  fr: { translation: translationFR },
  ar: { translation: translationAR },
};

const language = localStorage.getItem("I18N_LANGUAGE") || "en";
localStorage.setItem("I18N_LANGUAGE", language);

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true, // 🚀 Triggers missing key handling
    missingKeyHandler: (lng, ns, key) => {
      const selectedLang = i18n.language || "es"; // ✅ Use the currently selected language
if(selectedLang !=="en"){
  return handleMissingKey(selectedLang, key)

}else{
  return 
}
    } , // 🌍 Fetch translation when missing!
  });

export default i18n;
